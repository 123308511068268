import React, { Component } from 'react';
import './ApplicationStep1.css';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import {
  handleChange,
  validateEmail,
  applyErrorClasses,
  hideError,
  switchForm,
  urlCourseSelector,
  setIsRemote,
} from '../../Utils/jsFunctions';
import { connect } from 'react-redux';
import CircleNav from '../../Components/CircleNav';
// import InternationalRemoteWarning from '../../Components/InternationalRemoteWarning';
import NoProgramSelectedWarning from '../../Components/NoProgramSelectedWarning';

//Redux
import { updateForm, setSelectedProgram } from '../../Redux/actions';
import CohortSummary from '../../Components/CohortSummary';
import FormHeader from '../../Components/FormHeader';

// an array of all states (plus US territories)
// in the format of { value: 'AL', label: 'Alabama' }
const stateOptions = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

class ApplicationStep1 extends Component {
  constructor(props) {
    super(props);
    let {
      first,
      last,
      email,
      phone,
      birthday,
      city,
      state,
      country,
      citizen,
      ethnicity,
      identify,
      address,
      validConfirmation,
      zipCode,
      shirtSize,
    } = this.props;

    this.state = {
      first,
      last,
      email,
      phone,
      birthday,
      city,
      state: state || 'AL',
      country: country || 'US',
      citizen,
      ethnicity,
      identify,
      address,
      validConfirmation,
      validatedAddress: {
        hasRun: false,
        isValidated: false,
        err: null,
      },
      zipCode,
      stateRestrictions: '',
      shirtSize,
    };

    //refs for error handling
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.phoneRef = React.createRef();
    this.birthdayRef = React.createRef();
    this.cityRef = React.createRef();
    this.stateRef = React.createRef();
    this.countryRef = React.createRef();
    this.citizenRef = React.createRef();
    this.ethnicityRef = React.createRef();
    this.identifyRef = React.createRef();
    this.addressRef = React.createRef();
    this.selectedProgramRef = React.createRef();
    this.confirmationRef = React.createRef();
    this.zipRef = React.createRef();
    this.shirtSizeRef = React.createRef();

    //ref for scrolling
    this.topRef = React.createRef();

    //bind util functions
    this.switchForm = switchForm.bind(this);
  }

  componentDidMount() {
    const { selectedProgram } = this.props;
    const { sf_cohort_id } = selectedProgram || {};
    if (sf_cohort_id) {
      axios
        .get('/api/state-restrictions?cohort=' + sf_cohort_id)
        .then((response) => {
          if (response.data && response.data.restrictions) {
            this.setState({
              stateRestrictions: response.data.restrictions,
            });
          }
        });
    }
  }

  handleNext = (e) => {
    e.preventDefault();
    let { course } = queryString.parse(this.props.location.search);
    if (!course) course = urlCourseSelector(this.props.selectedProgram || {});
    let { updateForm } = this.props;
    //if this passes, save info to redux for smart form validation
    if (this.validateInfo()) {
      updateForm(this.state);
      this.props.history.push(`/application/step2?course=${course}`);
    } else {
      document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  validateInfo() {
    //assume info is good
    let passedValidation = true;
    let invalidFields = [];
    let { course } = queryString.parse(this.props.location.search);
    let { selectedProgram } = this.props;

    let {
      first,
      last,
      phone,
      email,
      birthday,
      city,
      address,
      state,
      country,
      citizen,
      ethnicity,
      identify,
      validConfirmation,
      validatedAddress,
      zipCode,
      shirtSize,
    } = this.state;

    let refs = [
      { val: first, refName: 'firstNameRef' },
      { val: last, refName: 'lastNameRef' },
      { val: email, refName: 'emailRef' },
      { val: birthday.value, refName: 'birthdayRef' },
      { val: city, refName: 'cityRef' },
      { val: state, refName: 'stateRef' },
      { val: address, refName: 'addressRef' },
      { val: citizen.touched, refName: 'citizenRef' },
      { val: ethnicity.touched, refName: 'ethnicityRef' },
      { val: phone.value, refName: 'phoneRef' },
      { val: zipCode, refName: 'zipRef' },
      { val: shirtSize, refName: 'shirtSizeRef' },
      { val: country, refName: 'countryRef' },
    ];

    refs.forEach((refObj) => {
      if (!refObj.val) {
        invalidFields.push(refObj.refName);
        passedValidation = false;
      } else {
        hideError.call(this, refObj.refName);
      }
    });

    // if (
    //   citizen.touched &&
    //   !citizen.status &&
    //   !validConfirmation.status &&
    //   validatedAddress.err
    // ) {
    //   invalidFields.push('confirmationRef');
    //   passedValidation = false;
    // } else hideError.call(this, 'confirmationRef');

    if (!selectedProgram.cohort_id) {
      if (invalidFields.indexOf('selectedProgramRef') === -1)
        invalidFields.push('selectedProgramRef');
      passedValidation = false;
    } else hideError.call(this, 'selectedProgramRef');

    if (!validateEmail(email)) {
      if (invalidFields.indexOf('emailRef') === -1)
        invalidFields.push('emailRef');
      passedValidation = false;
    } else hideError.call(this, 'emailRef');

    if (!this.validatePhone(phone.value)) {
      if (invalidFields.indexOf('phoneRef') === -1)
        invalidFields.push('phoneRef');
      passedValidation = false;
    } else hideError.call(this, 'phoneRef');

    if (!this.validateBirthday(birthday.formattedValue)) {
      if (invalidFields.indexOf('birthdayRef') === -1)
        invalidFields.push('birthdayRef');
      passedValidation = false;
    } else hideError.call(this, 'birthdayRef');

    if (course === 'online-python') {
      if (identify !== 'yes' && identify !== 'no') {
        if (invalidFields.indexOf('identifyRef') === -1)
          invalidFields.push('identifyRef');
        passedValidation = false;
      } else hideError.call(this, 'identifyRef');
    }

    if (invalidFields.length) applyErrorClasses.call(this, invalidFields);

    return passedValidation;
  }

  validatePhone(phone) {
    let isValid = true;
    if (!/^\d{10}$/.test(phone)) isValid = false;
    return isValid;
  }

  validateBirthday(birthday) {
    //Easier to set to false if it fails any test and then return at bottom
    let isValid = true;
    //Correct format should be mm/dd/yyyy
    let split = birthday.split('/');
    //test to make sure it passes structure first
    if (!/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/.test(birthday)) isValid = false;
    //now do valid month checks
    let mm = parseInt(split[0]);
    let dd = parseInt(split[1]);
    if (isNaN(split[0]) || mm < 0 || mm > 12) isValid = false;
    if (isNaN(split[1]) || dd < 0 || dd > 31) isValid = false;
    return isValid;
  }

  handlePhoneChange = (values) => {
    this.setState({
      phone: values,
    });
  };

  handleBirthdayChange = (values) => {
    this.setState({
      birthday: values,
    });
  };
  // Removed 10/18/20 for allowing international applications
  // validateUSAddress = async () => {
  //   const { city, address, state, citizen } = this.state;
  //   const { course } = queryString.parse(this.props.location.search);

  //   if (
  //     !city ||
  //     !address ||
  //     !state ||
  //     !citizen.touched ||
  //     citizen.status ||
  //     course === 'online-python'
  //   )
  //     return;

  //   try {
  //     await axios.post('/api/addressValidation', {
  //       city,
  //       address,
  //       state,
  //     });
  //     this.setState({
  //       validatedAddress: {
  //         hasRun: true,
  //         isValidated: true,
  //         err: null,
  //       },
  //     });
  //   } catch (err) {
  //     if (err.response) {
  //       this.setState({
  //         validatedAddress: {
  //           hasRun: true,
  //           isValidated: false,
  //           err: err.response.data,
  //         },
  //       });
  //     }
  //   }
  // };

  render() {
    let {
      first,
      last,
      email,
      phone,
      birthday,
      city,
      state,
      country,
      citizen,
      ethnicity,
      identify,
      address,
      validatedAddress,
      validConfirmation,
      zipCode,
      shirtSize,
    } = this.state;
    const { course } = queryString.parse(this.props.location.search);
    const isRemote = setIsRemote(this.props.selectedProgram);

    return (
      <section className="view-page-container">
        <FormHeader course={course} />
        <div className="view-page-wrapper">
          <div ref={this.topRef}>
            <form onSubmit={this.handleNext}>
              {!this.props.selectedProgram.cohort_id &&
                this.props.showNoMatch && (
                  <NoProgramSelectedWarning
                    updateForm={this.props.updateForm}
                    stateObj={this.state}
                  />
                )}

              <div className="form-section">
                <p>Contact Information</p>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.firstNameRef}
                  >
                    <label>
                      First Name <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={first}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'first')
                      }
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">
                      First name is required
                    </p>
                  </div>
                  <div
                    className="flex-column form-spacing"
                    ref={this.lastNameRef}
                  >
                    <label>
                      Last Name <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={last}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'last')
                      }
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">
                      Last name is required
                    </p>
                  </div>
                </div>
                <div className="form-row">
                  <div className="flex-column form-spacing" ref={this.emailRef}>
                    <label>
                      Email <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'email')
                      }
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">Email is required</p>
                  </div>
                </div>
                <div className="form-row">
                  <div className="flex-column form-spacing" ref={this.phoneRef}>
                    <label>
                      Phone Number <span className="red-star">*</span>
                    </label>
                    <NumberFormat
                      format="(###) ###-####"
                      name="phoneNumberInput"
                      onValueChange={this.handlePhoneChange}
                      value={phone.value}
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">
                      Phone number is required
                    </p>
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.birthdayRef}
                  >
                    <label>
                      Birthday (MM/DD/YYYY) <span className="red-star">*</span>
                    </label>
                    <NumberFormat
                      format="##/##/####"
                      name="birthdayInput"
                      onValueChange={this.handleBirthdayChange}
                      value={birthday.value}
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">Birthday is required</p>
                  </div>
                </div>
                <p style={{ marginBottom: 0 }}>Address Information</p>

                <aside className="state-restrictions">
                  {this.state.stateRestrictions && (
                    <>
                      There are currently restrictions in place that prevent us
                      from admitting students from the following states:{' '}
                      {this.state.stateRestrictions}
                    </>
                  )}
                </aside>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.countryRef}
                  >
                    <label>
                      Country <span className="red-star">*</span>
                    </label>
                    <div>
                      <input
                        id="country-us"
                        type="radio"
                        value="US"
                        checked={country === 'US'}
                        onChange={(e) =>
                          handleChange.call(this, e.target.value, 'country')
                        }
                      />
                      <label htmlFor="country-us">United States</label>
                      <input
                        id="country-intl"
                        type="radio"
                        value=""
                        checked={country !== 'US'}
                        onChange={(e) => handleChange.call(this, '', 'country')}
                      />
                      <label htmlFor="country-intl">International</label>
                    </div>

                    {country !== 'US' && (
                      <div
                        style={{
                          marginTop: '1rem',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <label>
                          Country Name <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          value={country}
                          onChange={(e) =>
                            handleChange.call(this, e.target.value, 'country')
                          }
                          className="input-text-normal"
                        />
                      </div>
                    )}
                    <p className="error-message hidden">Address is required</p>
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.addressRef}
                  >
                    <label>
                      Address <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'address')
                      }
                      // onBlur={this.validateUSAddress}
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">Address is required</p>
                  </div>
                  {validatedAddress.hasRun &&
                    validatedAddress.err &&
                    citizen.touched &&
                    !citizen.status &&
                    course !== 'online-python' && (
                      <div
                        className="flex-column form-spacing"
                        ref={this.confirmationRef}
                      >
                        <label>
                          I certify that the address entered is a valid US
                          postal address
                          <span className="red-star">*</span>
                        </label>
                        <input
                          type="checkbox"
                          checked={
                            validConfirmation.touched
                              ? validConfirmation.status
                              : false
                          }
                          name="validConfirmation.true"
                          onChange={(e) =>
                            handleChange.call(
                              this,
                              true,
                              'validConfirmation.status',
                            )
                          }
                        />
                        <p className="error-message hidden">
                          Confirmation required
                        </p>
                      </div>
                    )}
                </div>

                <div className="form-row">
                  <div className="flex-column form-spacing" ref={this.cityRef}>
                    <label>
                      City <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'city')
                      }
                      // onBlur={this.validateUSAddress}
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">City is required</p>
                  </div>
                  <div className="flex-column form-spacing" ref={this.stateRef}>
                    <label>
                      State/Region <span className="red-star">*</span>
                    </label>
                    {country === 'US' ? (
                      <select
                        value={state}
                        onChange={(e) =>
                          handleChange.call(this, e.target.value, 'state')
                        }
                        // onBlur={this.validateUSAddress}
                        className="input-text-normal"
                      >
                        {stateOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={state}
                        onChange={(e) =>
                          handleChange.call(this, e.target.value, 'state')
                        }
                        className="input-text-normal"
                      />
                    )}
                    <p className="error-message hidden">
                      State/Region is required
                    </p>
                  </div>
                </div>
                <div className="form-row">
                  <div className="flex-column form-spacing" ref={this.zipRef}>
                    <label>
                      Zip/Postal Code <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={zipCode}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'zipCode')
                      }
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">Zip Code is required</p>
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.shirtSizeRef}
                  >
                    <label>
                      What is your preferred t-shirt size?{' '}
                      <span className="red-star">*</span>
                    </label>
                    <select
                      value={shirtSize}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'shirtSize')
                      }
                      className="input-dropdown"
                    >
                      <option value="" disabled>
                        Please select....
                      </option>
                      {['xs', 's', 'm', 'l', 'xl', 'xxl'].map((size) => (
                        <option key={size} value={size}>
                          {size.toUpperCase()}
                        </option>
                      ))}
                    </select>
                    <p className="error-message hidden">Select a size</p>
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.citizenRef}
                  >
                    <label>
                      Are you a US Citizen or permanent resident?{' '}
                      <span className="red-star">*</span>
                    </label>
                    <span>
                      <input
                        type="checkbox"
                        checked={citizen.touched ? citizen.status : false}
                        onChange={(e) =>
                          handleChange.call(this, true, 'citizen.status')
                        }
                        name="citizen.true"
                      />
                      <label htmlFor="citizen.true">Yes</label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={citizen.touched ? !citizen.status : false}
                        onChange={(e) => {
                          handleChange.call(
                            this,
                            false,
                            'citizen.status',
                            // this.validateUSAddress,
                          );
                        }}
                        name="citizen.false"
                      />
                      <label htmlFor="citizen.false">No</label>
                    </span>
                    <p className="error-message hidden">Pick yes or no</p>
                    {/* 10/18/20 allowing international applicants again */}
                    {/* {citizen.touched && !citizen.status && (
                      <InternationalRemoteWarning
                        isConditional={true}
                        isRemote={isRemote}
                      />
                    )} */}
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.ethnicityRef}
                  >
                    <label>
                      Ethnicity (Please select all that apply){' '}
                      <span className="red-star">*</span>
                    </label>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.americanIndian}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.americanIndian',
                          )
                        }
                        name="ethnicity.americanIndian"
                      />
                      <label htmlFor="ethnicity.americanIndian">
                        American Indian or Alaska Native
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.asian}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.asian',
                          )
                        }
                        name="ethnicity.asian"
                      />
                      <label htmlFor="ethnicity.asian">Asian</label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.black}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.black',
                          )
                        }
                        name="ethnicity.black"
                      />
                      <label htmlFor="ethnicity.black">
                        Black or African American
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.hispanic}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.hispanic',
                          )
                        }
                        name="ethnicity.hispanic"
                      />
                      <label htmlFor="ethnicity.hispanic">
                        Hispanic/Latina
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.nativeHawaiian}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.nativeHawaiian',
                          )
                        }
                        name="ethnicity.nativeHawaiian"
                      />
                      <label htmlFor="ethnicity.nativeHawaiian">
                        Native Hawaiian or Other Pacific Islander
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.white}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.white',
                          )
                        }
                        name="ethnicity.white"
                      />
                      <label htmlFor="ethnicity.white">White</label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.decline}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.decline',
                          )
                        }
                        name="ethnicity.decline"
                      />
                      <label htmlFor="ethnicity.decline">
                        Decline to State
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={ethnicity.other}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.checked,
                            'ethnicity.other',
                          )
                        }
                        name="ethnicity.other"
                      />
                      <label htmlFor="ethnicity.other">Other</label>
                    </span>
                    <p className="error-message hidden">Pick at least one</p>
                  </div>
                </div>
                {course === 'online-python' ? (
                  <div className="form-row">
                    <div
                      className="flex-column form-spacing"
                      ref={this.identifyRef}
                    >
                      <label>
                        Do you identify as a woman?{' '}
                        <span className="red-star">*</span>
                      </label>
                      <select
                        value={identify}
                        onChange={(e) =>
                          handleChange.call(this, e.target.value, 'identify')
                        }
                        className="input-dropdown"
                      >
                        <option value="please..." disabled>
                          Please select....
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      <p className="error-message hidden">Select yes or no</p>
                    </div>
                  </div>
                ) : null}

                <div className="form-row form-button-row">
                  <button
                    onChange={this.handleNext}
                    className="btn-red centered"
                  >
                    <span className="centered">Next</span>
                    <span className="float-right">></span>
                  </button>
                </div>
              </div>
            </form>
            <CircleNav
              step="step1"
              switchForm={this.switchForm}
              course={course}
              selectedProgram={this.props.selectedProgram}
            />
            <CohortSummary />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    first: state.first,
    last: state.last,
    email: state.email,
    birthday: state.birthday,
    city: state.city,
    state: state.state,
    country: state.country,
    citizen: state.citizen,
    ethnicity: state.ethnicity,
    identify: state.identify,
    phone: state.phone,
    address: state.address,
    zipCode: state.zipCode,
    selectedProgram: state.selectedProgram,
    validConfirmation: state.validConfirmation,
    shirtSize: state.shirtSize,
  };
}

export default connect(mapStateToProps, { updateForm, setSelectedProgram })(
  ApplicationStep1,
);
