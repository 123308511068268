import React, { Component } from 'react';
import { connect } from 'react-redux';
import { captureException } from '@sentry/browser';
import { concatErrorQueries } from '../../../Utils/errorHandling';
import ConfirmationDialog from '../../../Components/ConfirmationDialog';
// import InternationalRemoteWarning from '../../../Components/InternationalRemoteWarning';
import {
  handleChange,
  validateEmail,
  applyErrorClasses,
  hideError,
  checkDiscountCode,
  switchForm,
  setIsRemote,
  getUtmParams,
  // setIsDisabled,
} from '../../../Utils/jsFunctions';
import { updateForm, setSelectedProgram } from '../../../Redux/actions';
import axios from 'axios';
import CircleNav from '../../../Components/CircleNav';
import { deleteCookie } from '../../../Utils/jsFunctions';
import { validateSelectedCohort } from '../utils/componentFunctions';
import NoProgramSelectedWarning from '../../../Components/NoProgramSelectedWarning';
const gtag = window.gtag;

class Software3 extends Component {
  constructor(props) {
    super(props);

    let { interested, attendReason, hearAbout, selectedProgram, discountCode } =
      this.props;

    this.state = {
      interested,
      attendReason,
      hearAbout,
      payOption: discountCode ? 'discountCode' : 'please...',
      discountCode: discountCode ? discountCode : '',
      discountAmount: '$0',
      discountedTotal: selectedProgram.price || '$1500',
      billingEmail: '',
      cardNumber: '',
      nameOnCard: '',
      mm: '',
      yy: '',
      code: '',
      submitting: false,
      formattedCardNumber: '',
      cohortSelected: validateSelectedCohort.call(this),
      redirectLink: null,
      redirectLinkLoading: true,
      partnershipSpecificQuestions: null,
      partnershipSpecificError: false,
      loading: true,
      partnershipSpecificAnswers: [],
      partnershipSpecificInvalid: [],
    };

    //refs
    this.attendReasonRef = React.createRef();
    this.hearAboutRef = React.createRef();
    this.payOptionRef = React.createRef();
    this.billingEmailRef = React.createRef();
    this.cardNumberRef = React.createRef();
    this.nameRef = React.createRef();
    this.mmRef = React.createRef();
    this.yyRef = React.createRef();
    this.codeRef = React.createRef();
    this.submitRef = React.createRef();
    this.discountCodeRef = React.createRef();

    //bind util functions
    this.switchForm = switchForm.bind(this);
  }

  componentDidMount() {
    this.getPartnershipSpecificQuestions(
      this.props.selectedProgram && this.props.selectedProgram.sf_cohort_id,
    );

    this.getRedirectLink(
      this.props.selectedProgram && this.props.selectedProgram.sf_cohort_id,
    );
    let { discountCode, discountCodeMeta, selectedProgram } = this.props;
    // handle discount code if one was passed from redux
    if (discountCode && (!discountCodeMeta || !discountCodeMeta.validated)) {
      this.validateDiscountCode(discountCode);
    } else if (discountCodeMeta) {
      // set discount amount based on previous calculations
      this.setState({
        discountAmount: `$${
          discountCodeMeta.discountDollarAmount ||
          (discountCodeMeta.discountPercentage / 100) *
            selectedProgram.price_int
        }`,
        discountedTotal: `$${selectedProgram.discountedTotal}`,
      });
    }
  }

  goBack = (e) => {
    e.preventDefault();

    let { history, updateForm } = this.props;

    let { interested, attendReason, hearAbout } = this.state;

    let updateObject = {
      interested,
      attendReason,
      hearAbout,
    };

    updateForm(updateObject);
    history.push(`/application/step2?course=software-engineering`);
  };

  handleSubmit = (e, submitVal) => {
    if (e) e.preventDefault();
    if (submitVal === 'cancel') return;

    if (!this.state.submitting) {
      this.setState(
        {
          submitting: true,
        },
        () => {
          let submitButton = this.submitRef.current;
          submitButton.disabled = true;
          submitButton.classList.add('disabled-button');
          let { updateForm } = this.props;
          let { interested, attendReason, hearAbout } = this.state;

          let updateObject = {
            interested,
            attendReason,
            hearAbout,
          };
          //if this passes, save info to redux for smart form validation
          if (this.validateInfo()) {
            updateForm(updateObject);
            this.formSubmit();
          } else {
            this.setState(
              {
                submitting: false,
              },
              () => {
                document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                submitButton.disabled = false;
                submitButton.classList.remove('disabled-button');
              },
            );
          }
        },
      );
    }
  };

  validateInfo() {
    //assume info is good
    let passedValidation = true;

    let invalidFields = [];

    let { isPrepProgram } = this.props;

    let {
      attendReason,
      hearAbout,
      payOption,
      billingEmail,
      cardNumber,
      nameOnCard,
      mm,
      yy,
      code,
      discountedTotal,
    } = this.state;

    let refs = [
      { val: attendReason, refName: 'attendReasonRef' },
      { val: hearAbout.touched, refName: 'hearAboutRef' },
    ];

    if (isPrepProgram && discountedTotal !== '$0') {
      refs.push(
        { val: billingEmail, refName: 'billingEmailRef' },
        { val: cardNumber, refName: 'cardNumberRef' },
        { val: nameOnCard, refName: 'nameRef' },
        { val: mm, refName: 'mmRef' },
        { val: yy, refName: 'yyRef' },
        { val: code, refName: 'codeRef' },
      );
    }

    refs.forEach((refObj) => {
      if (!refObj.val) {
        invalidFields.push(refObj.refName);
        passedValidation = false;
      } else {
        hideError.call(this, refObj.refName);
      }
    });

    // if total is 0, skip this validation
    if (discountedTotal !== '$0') {
      if (isPrepProgram && payOption === 'please...') {
        if (invalidFields.indexOf('payOptionRef') === -1)
          invalidFields.push('payOptionRef');
        passedValidation = false;
      } else hideError.call(this, 'payOptionRef');

      if (isPrepProgram && !validateEmail(billingEmail)) {
        if (invalidFields.indexOf('billingEmailRef') === -1)
          invalidFields.push('billingEmailRef');
        passedValidation = false;
      } else hideError.call(this, 'billingEmailRef');
    }

    if (invalidFields.length) applyErrorClasses.call(this, invalidFields);

    // partnership-specific fields are handled differently
    if (this.state.partnershipSpecificQuestions) {
      const partnershipSpecificInvalid = [];
      this.state.partnershipSpecificQuestions.forEach((question, index) => {
        if (!this.state.partnershipSpecificAnswers[index]) {
          passedValidation = false;
          partnershipSpecificInvalid[index] = true;
        }
      });
      this.setState({
        partnershipSpecificInvalid,
      });
    }

    return passedValidation;
  }

  formSubmit = async () => {
    let {
      selectedProgram,
      first,
      last,
      email,
      birthday,
      city,
      state,
      country,
      zipCode,
      citizen,
      ethnicity,
      identify,
      linkedin,
      github,
      otherLinks,
      resumeFile,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      phone,
      address,
      shirtSize,
    } = this.props;

    let {
      interested,
      attendReason,
      hearAbout,
      discountCode,
      discountAmount,
      discountedTotal,
      billingEmail,
      cardNumber,
      nameOnCard,
      mm,
      yy,
      code,
      partnershipSpecificAnswers,
    } = this.state;

    let completedForm = {
      discountCode,
      discountAmount,
      discountedTotal,
      billingEmail,
      cardNumber,
      nameOnCard,
      mm,
      yy,
      code,
      interested,
      attendReason,
      hearAbout,
      selectedProgram,
      first,
      last,
      email,
      birthday,
      city,
      state,
      zipCode,
      country,
      citizen,
      ethnicity,
      identify,
      linkedin,
      github,
      otherLinks,
      resumeFile,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      phone,
      address,
      partnershipSpecificAnswers,
      shirtSize,
    };

    const utm = getUtmParams();

    try {
      await axios.post('/api/submitForm?type=software-engineering', {
        ...completedForm,
        utm,
      });
      if (gtag) {
        // Fire off event to let marketing know there was an application submission
        gtag('event', 'application_submitted', {
          event_category: 'admissions',
          event_label: selectedProgram.program_identifier,
        });
      }
      if (this.state.redirectLink) {
        window.location = this.state.redirectLink;
        return;
      }

      // add query strings to this to fill out their information automatically
      if (selectedProgram.program_identifier !== 'software-engineering-prep') {
        deleteCookie('dc');
        if (
          selectedProgram.cohort_name.toLowerCase().indexOf('walmart') !== -1
        ) {
          window.location = `https://calendly.com/admissions-168/admissions-consultation-sponsored-3?first_name=${first}&last_name=${last}&email=${email}`;
        } else if (
          selectedProgram.cohort_name.toLowerCase().indexOf('perficient') !== -1
        ) {
          // perf 7 link
          window.location = `https://calendly.com/admissions-168/perficient-admissions-group-q-a?first_name=${first}&last_name=${last}&email=${email}`;
          // regular perf link
          // window.location = `https://calendly.com/admissions-168/admissions-consultation-sponsored-2?first_name=${first}&last_name=${last}&email=${email}`;
        } else if (
          selectedProgram.cohort_name.toLowerCase().indexOf('optum') !== -1
        ) {
          window.location = `https://calendly.com/admissions-168/admissions-consultation-sponsored-1?first_name=${first}&last_name=${last}&email=${email}`;
        } else if (
          selectedProgram.cohort_name.toLowerCase().indexOf('target') !== -1
        ) {
          window.location = `https://calendly.com/admissions-168/target-hackbright-admissions-consultation?first_name=${first}&last_name=${last}&email=${email}`;
        } else {
          window.location = `https://calendly.com/admissions-168/hackbright-admissions-consultation?first_name=${first}&last_name=${last}&email=${email}`;
        }
      } else {
        this.props.history.push(
          `/submission/success?program=${selectedProgram.program_identifier}`,
        );
      }
    } catch (err) {
      let queryString = '';
      if (err.response && err.response.data)
        queryString = concatErrorQueries(err.response.data);
      this.props.history.push(`/submission/error${queryString}`);
    }
  };

  validateCreditCard = (invalidFields) => {
    let passedValidation = true;

    let { cardNumber, mm, yy, code } = this.state;

    if (isNaN(cardNumber)) {
      if (invalidFields.indexOf('cardNumberRef') === -1)
        invalidFields.push('cardNumberRef');
      passedValidation = false;
    }

    if (isNaN(mm) || parseInt(mm) < 0 || parseInt(mm) > 12 || mm.length !== 2) {
      if (invalidFields.indexOf('mmRef') === -1) invalidFields.push('mmRef');
      passedValidation = false;
    }

    if (isNaN(yy) || parseInt(yy) < 0 || parseInt(yy) > 31 || yy.length !== 2) {
      if (invalidFields.indexOf('yyRef') === -1) invalidFields.push('yyRef');
      passedValidation = false;
    }

    if (isNaN(code) || code.length !== 3) {
      if (invalidFields.indexOf('codeRef') === -1)
        invalidFields.push('codeRef');
      passedValidation = false;
    }

    return passedValidation;
  };

  validateDiscountCode = async () => {
    const { discountCode } = this.state;
    const { selectedProgram } = this.props;
    let response = await checkDiscountCode(
      discountCode,
      selectedProgram.program_identifier,
    );
    let { data } = response;
    if (data.length) {
      if (
        data[0].Type__c === 'Dollar Amount' &&
        typeof data[0].Dollar_Amount__c === 'number'
      ) {
        this.setState({
          discountAmount: `$${data[0].Dollar_Amount__c}`,
          discountedTotal: `$${
            selectedProgram.price_int - data[0].Dollar_Amount__c
          }`,
        });
        hideError.call(this, 'discountCodeRef');
      } else if (
        data[0].Type__c === 'Percentage' &&
        typeof data[0].discount_percentage__c === 'number'
      ) {
        this.setState({
          discountedTotal: `$${
            selectedProgram.price_int -
            (data[0].discount_percentage__c / 100) * selectedProgram.price_int
          }`,
          discountAmount: `$${
            (data[0].discount_percentage__c / 100) * selectedProgram.price_int
          }`,
        });
        hideError.call(this, 'discountCodeRef');
      } else applyErrorClasses.call(this, ['discountCodeRef']);
    } else {
      if (
        this.state.discountAmount !== '$0' ||
        this.state.discountedTotal !== selectedProgram.price
      )
        this.setState({
          discountAmount: '$0',
          discountedTotal: selectedProgram.price,
        });
      //handle error showing discount code didnt work
      applyErrorClasses.call(this, ['discountCodeRef']);
    }
  };

  async getPartnershipSpecificQuestions(cohortId) {
    if (!cohortId) {
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: true });
    try {
      const response = await axios.get(
        '/api/partnership-specific-questions?cohort=' + cohortId,
      );

      this.setState({
        partnershipSpecificQuestions: response.data,
        loading: false,
      });
    } catch (err) {
      captureException(err, {
        extra: {
          message: 'Error getting partnership specific questions',
          cohortId,
        },
      });
      this.setState({ loading: false, partnershipSpecificError: true });
    }
  }

  async getRedirectLink(cohortId) {
    if (!cohortId) {
      this.setState({ redirectLinkLoading: false });
      return;
    }
    this.setState({ redirectLinkLoading: true });
    try {
      const response = await axios.get('/api/redirect-link?cohort=' + cohortId);

      this.setState({
        redirectLink: response.data,
        redirectLinkLoading: false,
      });
    } catch (err) {
      captureException(err, {
        extra: {
          message: 'Error getting redirect link',
          cohortId,
        },
      });
      this.setState({ redirectLinkLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedProgram } = this.props;
    const { sf_cohort_id } = selectedProgram || {};
    const { selectedProgram: prevSelectedProgram } = prevProps;
    const { prev_sf_cohort_id } = prevSelectedProgram || {};
    if (!sf_cohort_id) {
      return;
    }
    if (this.state.loading) {
      return;
    }
    if (prev_sf_cohort_id && prev_sf_cohort_id !== sf_cohort_id) {
      this.getPartnershipSpecificQuestions(sf_cohort_id);
      if (!this.state.redirectLinkLoading) {
        this.getRedirectLink(sf_cohort_id);
      }
    } else if (
      sf_cohort_id &&
      !prev_sf_cohort_id &&
      !this.state.partnershipSpecificQuestions &&
      !this.state.partnershipSpecificError
    ) {
      this.getPartnershipSpecificQuestions(sf_cohort_id);
    }
  }

  render() {
    let {
      interested,
      attendReason,
      hearAbout,
      payOption,
      discountCode,
      discountAmount,
      discountedTotal,
      billingEmail,
      nameOnCard,
      mm,
      yy,
      code,
      formattedCardNumber,
    } = this.state;

    let { course, isPrepProgram, selectedProgram } = this.props;
    const isRemote = setIsRemote(selectedProgram);
    // const isDisabled = setIsDisabled(isRemote, this.props.citizen);

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-section">
            <div className="form-row">
              <div className="flex-column form-spacing">
                <label>
                  I am interested in (please select all that apply to you):
                </label>
                <span>
                  <input
                    type="checkbox"
                    checked={interested.softwareEngineering}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'interested.softwareEngineering',
                      )
                    }
                    name="interested.softwareEngineering"
                  />
                  <label htmlFor="interested.softwareEngineering">
                    Software Engineering
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={interested.startingCompany}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'interested.startingCompany',
                      )
                    }
                    name="interested.startingCompany"
                  />
                  <label htmlFor="interested.startingCompany">
                    Starting my own company
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={interested.dataScience}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'interested.dataScience',
                      )
                    }
                    name="interested.dataScience"
                  />
                  <label htmlFor="interested.dataScience">
                    Data Science/Analytics
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={interested.technicalManagement}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'interested.technicalManagement',
                      )
                    }
                    name="interested.technicalManagement"
                  />
                  <label htmlFor="interested.technicalManagement">
                    Technical Product Management
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={interested.allAbove}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'interested.allAbove',
                      )
                    }
                    name="interested.allAbove"
                  />
                  <label htmlFor="interested.allAbove">All of the above</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={interested.notSureYet}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'interested.notSureYet',
                      )
                    }
                    name="interested.notSureYet"
                  />
                  <label htmlFor="interested.notSureYet">
                    Not quite sure yet, I'm still explorying my options.
                  </label>
                </span>
              </div>
            </div>
            <div className="form-row">
              <div
                className="flex-column form-spacing"
                ref={this.attendReasonRef}
              >
                <label>
                  Why would you like to attend Hackbright?{' '}
                  <span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  value={attendReason}
                  onChange={(e) =>
                    handleChange.call(this, e.target.value, 'attendReason')
                  }
                  className="input-text-large"
                />
                <p className="error-message hidden">
                  Please indicate why you would like to attend HackBright
                </p>
              </div>
            </div>
            <div className="form-row">
              <div className="flex-column form-spacing" ref={this.hearAboutRef}>
                <label>
                  How did you hear about Hackbright (please select all that
                  apply)? <span className="red-star">*</span>
                </label>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.currentStudent}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.currentStudent',
                      )
                    }
                    name="hearAbout.currentStudent"
                  />
                  <label htmlFor="hearAbout.currentStudent">
                    Current Student
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.alumna}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.alumna',
                      )
                    }
                    name="hearAbout.alumna"
                  />
                  <label htmlFor="hearAbout.alumna">Alumna</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.instructor}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.instructor',
                      )
                    }
                    name="hearAbout.instructor"
                  />
                  <label htmlFor="hearAbout.instructor">
                    Instructor/Staff Member
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.womenCode}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.womenCode',
                      )
                    }
                    name="hearAbout.womenCode"
                  />
                  <label htmlFor="hearAbout.womenCode">Women Who Code</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.google}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.google',
                      )
                    }
                    name="hearAbout.google"
                  />
                  <label htmlFor="hearAbout.google">Google Search</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.articleBlog}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.articleBlog',
                      )
                    }
                    name="hearAbout.articleBlog"
                  />
                  <label htmlFor="hearAbout.articleBlog">Article/Blog</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.event}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.event',
                      )
                    }
                    name="hearAbout.event"
                  />
                  <label htmlFor="hearAbout.event">Event</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.advertisement}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.advertisement',
                      )
                    }
                    name="hearAbout.advertisement"
                  />
                  <label htmlFor="hearAbout.advertisement">Advertisement</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.facebook}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.facebook',
                      )
                    }
                    name="hearAbout.facebook"
                  />
                  <label htmlFor="hearAbout.facebook">Facebook/Twitter</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.linkedin}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.linkedin',
                      )
                    }
                    name="hearAbout.linkedin"
                  />
                  <label htmlFor="hearAbout.linkedin">LinkedIn</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.currentEmployer}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.currentEmployer',
                      )
                    }
                    name="hearAbout.currentEmployer"
                  />
                  <label htmlFor="hearAbout.currentEmployer">
                    Current/Previous Employer
                  </label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    checked={hearAbout.other}
                    onChange={(e) =>
                      handleChange.call(
                        this,
                        e.target.checked,
                        'hearAbout.other',
                      )
                    }
                    name="hearAbout.other"
                  />
                  <label htmlFor="hearAbout.other">Other</label>
                </span>
                <p className="error-message hidden">
                  Please select at least 1 option
                </p>
              </div>
            </div>
            {this.state.partnershipSpecificQuestions &&
              this.state.partnershipSpecificQuestions.map((question, index) => {
                return (
                  <div key={question + index} className="form-row">
                    <div className="flex-column form-spacing">
                      <label>
                        {question}
                        <span className="red-star"> *</span>
                      </label>
                      <input
                        type="text"
                        value={
                          this.state.partnershipSpecificAnswers[index] || ''
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          this.setState((previousState) => {
                            const partnershipSpecificAnswers = [
                              ...previousState.partnershipSpecificAnswers,
                            ];
                            partnershipSpecificAnswers[index] = value;
                            return {
                              partnershipSpecificAnswers,
                            };
                          });
                        }}
                        className="input-text-large"
                      />
                      {this.state.partnershipSpecificInvalid[index] && (
                        <p className="error-message">Please enter a response</p>
                      )}
                    </div>
                  </div>
                );
              })}
            {discountedTotal !== '$0' && isPrepProgram ? null : (
              <>
                {/* <InternationalRemoteWarning
                  isConditional={true}
                  isRemote={isRemote}
                  citizen={this.props.citizen}
                /> */}
                {!this.state.cohortSelected && (
                  <NoProgramSelectedWarning
                    updateForm={this.props.updateForm}
                    stateObj={{
                      interested: this.state.interested,
                      hearAbout: this.state.hearAbout,
                      attendReason: this.state.attendReason,
                    }}
                  />
                )}
                <div className="form-row navigation-buttons form-button-row">
                  <button onClick={this.goBack} className="btn-white-red">
                    <span className="float-left">&lt;</span>
                    <span className="centered">Back</span>
                  </button>
                  <button
                    onClick={this.handleSubmit}
                    className={`btn-red centered${
                      !this.state.cohortSelected ? ' disabled-button' : ''
                    }`}
                    disabled={!this.state.cohortSelected}
                    ref={this.submitRef}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
          {isPrepProgram && discountedTotal !== '$0' ? (
            <ConfirmationDialog
              handleSubmit={this.handleSubmit}
              render={({ handleOpen }) => {
                return (
                  <div className="form-section">
                    <p>Payment Information</p>
                    <div className="form-row">
                      <div
                        className="flex-column form-spacing"
                        ref={this.payOptionRef}
                      >
                        <label>
                          How would you like to pay?{' '}
                          <span className="red-star">*</span>
                        </label>
                        <select
                          value={payOption}
                          onChange={(e) =>
                            handleChange.call(this, e.target.value, 'payOption')
                          }
                          className="input-text-dropdown"
                        >
                          <option value="please..." disabled>
                            Please select....
                          </option>
                          <option value="fullAmount">
                            I'll be paying the full{' '}
                            {selectedProgram.price || '$1500'} today.
                          </option>
                          <option value="discountCode">
                            I have a discount code!
                          </option>
                        </select>
                        <p className="error-message hidden">
                          Please select an option
                        </p>
                      </div>
                    </div>
                    {payOption === 'fullAmount' ? (
                      <div
                        className="form-row"
                        style={{ marginBottom: '10px' }}
                      >
                        <span>Tuition {selectedProgram.price || '$1500'}</span>
                      </div>
                    ) : null}
                    {payOption === 'discountCode' ? (
                      <div className="form-row">
                        <div
                          className="flex-column form-spacing"
                          ref={this.discountCodeRef}
                        >
                          <label>Discount Code</label>
                          <input
                            type="text"
                            value={discountCode}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.value,
                                'discountCode',
                              )
                            }
                            onBlur={this.validateDiscountCode}
                            className="input-text-normal"
                          />
                          <p className="error-message hidden">
                            Discount code is not valid
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {payOption === 'discountCode' ? (
                      <div className="form-row">
                        <div className="flex-column form-spacing">
                          <div className="flex-row">
                            <span>
                              Pre-Discount Total{' '}
                              {selectedProgram.price || '$1500'}
                            </span>
                          </div>
                          <div className="flex-row">
                            <b>Discount Amount {discountAmount}</b>
                          </div>
                          <div className="flex-row">
                            <span>Discounted Total {discountedTotal}</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="form-row">
                      <div
                        className="flex-column form-spacing"
                        ref={this.cardNumberRef}
                      >
                        <label>
                          Card Number <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="****************"
                          value={formattedCardNumber}
                          autoComplete="cc-number"
                          onChange={(e) =>
                            handleChange.call(
                              this,
                              e.target.value,
                              'cardNumber',
                            )
                          }
                          className="input-text-normal"
                        />
                        <p className="error-message hidden">
                          Card number is required
                        </p>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className="flex-column form-spacing"
                        ref={this.nameRef}
                      >
                        <label>
                          Name on Card <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          autoComplete="cc-name"
                          value={nameOnCard}
                          onChange={(e) =>
                            handleChange.call(
                              this,
                              e.target.value,
                              'nameOnCard',
                            )
                          }
                          className="input-text-normal"
                        />
                        <p className="error-message hidden">
                          Name on card is required
                        </p>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className="flex-column form-spacing"
                        ref={this.mmRef}
                      >
                        <label>
                          MM <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="MM"
                          value={mm}
                          onChange={(e) =>
                            handleChange.call(this, e.target.value, 'mm')
                          }
                          className="input-text-small"
                        />
                        <p className="error-message hidden">
                          Expiration month is required
                        </p>
                      </div>
                      <div
                        className="flex-column form-spacing"
                        ref={this.yyRef}
                      >
                        <label>
                          YY <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="YY"
                          value={yy}
                          onChange={(e) =>
                            handleChange.call(this, e.target.value, 'yy')
                          }
                          className="input-text-small"
                        />
                        <p className="error-message hidden">
                          Expiration year is required
                        </p>
                      </div>
                      <div
                        className="flex-column form-spacing"
                        ref={this.codeRef}
                      >
                        <label>
                          Code <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="***"
                          value={code}
                          autoComplete="cc-csc"
                          onChange={(e) =>
                            handleChange.call(this, e.target.value, 'code')
                          }
                          className="input-text-small"
                        />
                        <p className="error-message hidden">Code is required</p>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className="flex-column form-spacing"
                        ref={this.billingEmailRef}
                      >
                        <label>
                          Billing Email <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          value={billingEmail}
                          onChange={(e) =>
                            handleChange.call(
                              this,
                              e.target.value,
                              'billingEmail',
                            )
                          }
                          className="input-text-normal"
                        />
                        <p className="error-message hidden">
                          Billing email is required
                        </p>
                      </div>
                    </div>
                    {/* <InternationalRemoteWarning
                      isConditional={true}
                      isRemote={isRemote}
                      citizen={this.props.citizen}
                    /> */}
                    {!this.state.cohortSelected && (
                      <NoProgramSelectedWarning
                        updateForm={this.props.updateForm}
                        stateObj={{
                          interested: this.state.interested,
                          hearAbout: this.state.hearAbout,
                          attendReason: this.state.attendReason,
                        }}
                      />
                    )}
                    <div className="form-row navigation-buttons form-button-row">
                      <button onClick={this.goBack} className="btn-white-red">
                        <span className="float-left">&lt;</span>
                        <span className="centered">Back</span>
                      </button>
                      <button
                        onClick={handleOpen}
                        className={`btn-red centered${
                          !this.state.cohortSelected ? ' disabled-button' : ''
                        }`}
                        disabled={!this.state.cohortSelected}
                        ref={this.submitRef}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                );
              }}
            ></ConfirmationDialog>
          ) : null}
        </form>
        <CircleNav step="step3" switchForm={this.switchForm} course={course} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps, { updateForm, setSelectedProgram })(
  Software3,
);
