import {
  SET_SELECTED_PROGRAM,
  SET_COHORTS,
  UPDATE_FORM,
  SET_DISCOUNT,
} from '../actionCreators';

const initialState = {
  selectedProgram: {},
  cohorts: [],
  first: '',
  last: '',
  phone: {
    formattedValue: '',
    value: '',
    floatValue: '',
  },
  email: '',
  birthday: {
    formattedValue: '',
    value: '',
    floatValue: '',
  },
  city: '',
  address: '',
  state: '',
  citizen: {
    touched: false,
    status: false,
  },
  validConfirmation: {
    touched: false,
    status: false,
  },
  ethnicity: {
    touched: false,
    americanIndian: false,
    asian: false,
    black: false,
    hispanic: false,
    nativeHawaiian: false,
    white: false,
    decline: false,
    other: false,
  },
  identify: 'please...',
  linkedin: '',
  github: '',
  otherLinks: '',
  resumeFile: [],
  education: 'please...',
  nameOfSchool: '',
  major: '',
  certificates: '',
  experience: '',
  hearAbout: {
    touched: false,
    currentStudent: false,
    alumna: false,
    instructor: false,
    womenCode: false,
    google: false,
    articleBlog: false,
    event: false,
    advertisement: false,
    facebook: false,
    linkedin: false,
    currentEmployer: false,
    other: false,
  },
  applyingSoftwareProgram: {
    touched: false,
    status: false,
  },
  interested: {
    touched: false,
    softwareEngineering: false,
    startingCompany: false,
    dataScience: false,
    technicalManagement: false,
    allAbove: false,
    notSureYet: false,
  },
  attendReason: '',
  serverResumePath: '',
  isPrepProgram: false,
  onlineExperience: 0,
  onlineConfident: 0,
  onlineEnsureSuccess: '',
  zipCode: '',
  shirtSize: '',
};

export default function generalApplication(state = initialState, action) {
  let stateCopy;
  switch (action.type) {
    case SET_SELECTED_PROGRAM:
      stateCopy = Object.assign({}, state, {
        selectedProgram: action.payload.program,
        isPrepProgram: action.payload.isPrepProgram,
      });
      return stateCopy;
    case SET_COHORTS:
      stateCopy = Object.assign({}, state, { cohorts: action.payload });
      return stateCopy;
    case UPDATE_FORM:
      stateCopy = Object.assign({}, state, action.payload);
      return stateCopy;
    case SET_DISCOUNT:
      stateCopy = Object.assign({}, state, {
        discountCode: action.payload.discountCode,
        discountCodeMeta: action.payload.discountCodeMeta,
      });
      return stateCopy;
    default:
      return state;
  }
}
