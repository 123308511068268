import React, { Component } from 'react';
import { connect } from 'react-redux';

//Components
import CohortDisplay from '../../Components/CohortDisplay';
// import InternationalRemoteWarning from '../../Components/InternationalRemoteWarning';

import './ApplyPage.css';

class ApplyPage extends Component {
  render() {
    let { cohorts, discountCodeMeta } = this.props;

    let cohortComponents = cohorts
      .filter((c) => {
        // Hide all sponsored cohorts from the landing page
        if (typeof c.cohort_name !== 'string') return true;
        return (
          c.cohort_name.toLowerCase().indexOf('target') === -1 &&
          c.cohort_name.toLowerCase().indexOf('perficient') === -1 &&
          c.cohort_name.toLowerCase().indexOf('walmart') === -1 &&
          c.cohort_name.toLowerCase().indexOf('shipt') === -1
        );
      })
      .reduce(
        (dict, cohort) => {
          // If program assocations, check to make sure cohort applies. If no program associations but valid code, apply to all
          const cohortDisplay = (
            <CohortDisplay
              cohort={cohort}
              key={cohort.cohort_id}
              history={this.props.history}
            />
          );
          if (
            cohort.program_name === 'Software Engineering Part-Time' ||
            cohort.program_name === 'Software Engineering Full-Time'
          )
            dict['Software Engineering Full-Time & Part-Time Courses'].push(
              cohortDisplay,
            );
          if (cohort.program_name === 'Python 101')
            dict['Online Python 101 Courses'].push(cohortDisplay);
          if (cohort.program_name === 'Software Engineering Prep')
            dict['Prep Courses'].push(cohortDisplay);
          return dict;
        },
        {
          'Software Engineering Full-Time & Part-Time Courses': [],
          'Prep Courses': [],
          'Online Python 101 Courses': [],
        },
      );

    Object.keys(cohortComponents).forEach((key) => {
      if (!cohortComponents[key].length)
        cohortComponents[key].push(
          <p key={key.replace(/\s/g, '-')}>
            Hmm... It doesn't look like there are any upcoming cohorts at this
            time. Please reach out to our admissions team at{' '}
            <a href="mailto:admissions@hackbrightacademy.com">
              admissions@hackbrightacademy.com
            </a>{' '}
            to continue.
          </p>,
        );
    });

    return (
      <div className="apply-page-wrapper">
        <div className="apply-picture-container">
          <div className="picture-div">UPCOMING COHORTS</div>
        </div>
        <div className="apply-content-container">
          <div className="content-info-container">
            <div className="content-header">
              Hackbright Academy Courses and Deadlines
            </div>
            <div className="content-text">
              View upcoming dates, deadlines, &amp; locations for Hackbright's
              upcoming courses. Hackbright Academy offers a variety of
              full-time, part-time, and online programs so that you can learn
              software engineering in a format that best fits your lifestyle.
            </div>
            {/* <InternationalRemoteWarning /> */}
          </div>
          <div className="cohorts-main-container">
            <section className="program-section">
              <h2>Software Engineering Full-Time &amp; Part-Time Courses</h2>
              <section>
                {
                  cohortComponents[
                    'Software Engineering Full-Time & Part-Time Courses'
                  ]
                }
              </section>
            </section>
            <section className="program-section">
              <h2>Prep Courses</h2>
              <section>{cohortComponents['Prep Courses']}</section>
            </section>
            <section className="program-section">
              <h2>Online Python 101 Courses</h2>
              <section>{cohortComponents['Online Python 101 Courses']}</section>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cohorts: state.cohorts,
    discountCodeMeta: state.discountCodeMeta,
  };
}

export default connect(mapStateToProps)(ApplyPage);
